<template>
    <div style="margin-top: 100px">
        <BasePageBreadCrumb :pages="pages" :title="`${clients.length} corretores`" :menu="menu"/>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-8">
                                <div class="form-group d-flex">
                                    <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                    <button type="submit" class="btn btn-info" @click="fetchClients">Buscar</button>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                                <div class="form-group d-flex">
                                    <b-link :to="{name: 'BrokerNew'}" class=" ml-auto">
                                        <button type="submit" class="btn btn-dark"><i class="fas fa-street-view mr-2"></i>Novo corretor</button>
                                    </b-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card-list-legenda"> 
                            <div class="card-body-list-legend d-none d-lg-block"> 
                                <div class="col-12 p-0 align-self-center">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                                <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                            </div>
                                            <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                            </div>
                                            <div class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                            <p class="text-truncate title-legend-list">Nome</p>
                                                        </div>
                                                        <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                            <p class="text-truncate title-legend-list">E-mail</p>
                                                        </div>
                                                        <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                            <p class="text-truncate title-legend-list">Whatsapp</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="card-list" v-for="item in clients" :key="item.id" > 
                            <div class="card-body-list"> 
                                <div class="col-12 p-0 align-self-center">
                                    <div class="col-12">
                                        <div class="row" >
                                            <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                                <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                            </div>
                                            <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                <img v-if="item.image" :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                            </div>
                                            <div class="col-7 col-md-6 col-lg-8 p-0 order-3 align-self-center">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                            <h6 class="text-truncate card-body-list-title">{{ item.first_name }} {{ item.last_name }}</h6>
                                                            <h6 class="text-truncate cpf">{{ item.cpf }}</h6>
                                                        </div>
                                                        <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                            <h6 class="text-truncate e-mail">{{ item.email }}</h6>
                                                        </div>
                                                        <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                            <h6 class="text-truncate whats">{{ item.broker.whatsapp }}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div  class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                                 <b-link  v-if="item.broker.is_active==0" :id="'aprovar'+item.broker.id" @click="approveBroker(item.broker.id)" class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta mr-1" >&#10003;</b-link>
                                                <b-link :to="{name: 'BrokerEdit', params: {id: item.id}}" class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta" >+</b-link>
                                                <a class="btn btn-success ml-1 text-white rounded-circle btn-circle font-20 card-body-list-cta" target="_blank" :href="`https://api.whatsapp.com/send?phone=${item.cellphone ? item.cellphone.replace(/\D/g,'') : ''}`"><i class="fab fa-whatsapp font-20"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-12 text-center p-4" v-if="clients.length == 0">
                            Nenhum corretor encontrado.
                        </div>
    
                       
                        </div>
    
    
                        
                    </div>
                    <div class="col-12">
                        <BasePagination
                            v-if="clients.length > 0"
                            :totalPages="pagination.totalPages"
                            :activePage="pagination.currentPage"
                            @to-page="toPage"
                            @per-page="perPage" />
                    </div>
                </div>
            </div>
    </div>
    </template>
    <script>
    import BrokerService from "@/services/resources/BrokerService";
    const service = BrokerService.build();
    import BrokerApproveService from "@/services/resources/BrokerApproveService";
    const service2 = BrokerApproveService.build();
    
    export default {
        data(){
            return{
                clients: [],
                pagination: {
                    totalPages: 1,
                    currentPage: 1,
                    perPage: 10,
                    itens: 0
                },
                search: null,
                pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'corretores',
                        to: 'Broker'
                    }
                ],
                menu:[
                    {
                        name: 'Novo corretor',
                        to: 'ClientNew'
                    },
                    {
                        name: 'Corretores',
                        to: 'Client'
                    }
                ]
            }
        },
        methods:{
             toPage(page) {
                this.pagination.currentPage = page;
                this.fetchClients();
            },
            perPage(qtd) {
                this.pagination.currentPage = 1;
                this.pagination.perPage = qtd;
    
                this.fetchClients();
            },
            approveBroker(id) {
                   service2
                    .search({id:id})
                    .then(resp => {
                      if(resp=="aprovado"){
                        document.getElementById("aprovar"+id).style.display="none"
                      }
                    })
                    .catch(err => {
                        })
                        
            },
            fetchClients(){
    
                let data = {
                    page: this.pagination.currentPage,
                    per_page: this.pagination.perPage
                }
    
                if(this.search){
                    data['search'] = this.search;
                }
    
                 service
                    .search(data)
                    .then(resp => {
                        this.clients = resp.data.map(item => {
                            return {
                                ...item,
                                cellphone: item.cellphone ? item.cellphone : 'NÃO CADASTRADO ',
                                cpf: item.cpf ? item.cpf : item.cnpj,
                                first_name: item.first_name ? item.first_name : item.social_name,
                            }
                        });
                        this.pagination.totalPages = resp.last_page;
                        this.pagination.itens = resp.total;
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        mounted(){
            this.fetchClients();
        }
    }
    </script>